import React, { useState } from "react";

import Layout from "../components/layout";
import Title from "../components/title";
import SEO from "../components/seo";

function ContactPage() {
  const [isInputFocused, setIsInputFocused] = useState(false);

  return (
    <Layout isInputFocused={isInputFocused}>
      <SEO
        pageKeywords={[
          `contactar`
        ]}
        title="Contactar"
        lang="es"
      />
      <section className="mt-6 mb-16 md:container md:mx-auto p-5">
        <Title titleName="Hable con nosotros" twSize="text-4xl" />
        <form
          id="contact-form"
          name="contact-form"
          method="POST"
          action="/success"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact-form" />
          <div className="md:container md:mx-auto m-5">
            <div className="flex flex-col md:flex-row">
              <div className="w-full flex-1 md:pr-2">
                <label
                  htmlFor="nombre"
                  className="font-bold text-gray-600 text-xs leading-8 uppercase h-6 mt-3"
                >
                  Nombre
                </label>
                <div className="bg-white my-2 p-1 flex shadow-md rounded">
                  <input
                    name="nombre"
                    id="nombre"
                    placeholder="Mario"
                    className="p-1 px-2 w-full text-gray-800"
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                  />
                </div>
              </div>
              <div className="w-full flex-1 md:pl-2">
                <label
                  htmlFor="apellidos"
                  className="font-bold text-gray-600 text-xs leading-8 uppercase h-6 mt-3"
                >
                  Apellidos
                </label>
                <div className="bg-white my-2 p-1 flex shadow-md rounded">
                  <input
                    name="apellidos"
                    id="apellidos"
                    placeholder="Lopéz"
                    className="p-1 px-2 w-full text-gray-800"
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex-1">
              <label
                htmlFor="email"
                className="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
              >
                {" "}
                Correo electrónico
              </label>
              <div className="bg-white my-2 p-1 flex shadow-md rounded">
                <input
                  name="email"
                  id="email"
                  placeholder="mariolopez@ejemplo.com"
                  required
                  className="p-1 px-2 w-full text-gray-800"
                  onFocus={() => setIsInputFocused(true)}
                  onBlur={() => setIsInputFocused(false)}
                />
              </div>
            </div>
            <label
              htmlFor="mensaje"
              className="font-bold text-gray-600 text-xs leading-8 uppercase h-6 mt-3"
            >
              Mensaje
            </label>
            <p className="w-full text-gray-500 font-mono">
              pasteleriamissdulces@yahoo.es
            </p>
            <div className="bg-white my-2 p-1 flex shadow-md rounded">
              <textarea
                className="p-1 px-2 w-full text-gray-800"
                name="mensaje"
                id="mensaje"
                required
                cols="30"
                rows="10"
                placeholder="Escribe aquí..."
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => setIsInputFocused(false)}
              ></textarea>
            </div>
            <div className="w-1/3 mt-4 flex">
              <button
                type="submit"
                className="bg-myPinkMedium hover:bg-myViolet w-20 text-white font-semibold py-2 px-4 rounded"
              >
                Enviar
              </button>
            </div>
          </div>
        </form>
      </section>
    </Layout>
  );
}

export default ContactPage;
